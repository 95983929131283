import React from 'react';

const defaultState = {
  dark: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleDark: () => {},
};
const ThemeContext = React.createContext(defaultState);

interface ThemeProviderProps {
  children: React.ReactElement;
}
interface ThemeProviderState {
  dark: boolean;
}

class ThemeProvider extends React.Component<
  ThemeProviderProps,
  ThemeProviderState
> {
  state = {
    dark: false,
  };

  toggleDark = (): void => {
    const dark = !this.state.dark;
    this.setState({ dark });
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (prevState !== this.state) {
      const dark = this.state.dark;
      localStorage.setItem('dark', JSON.stringify(dark));
      document.documentElement.setAttribute(
        'data-theme',
        dark ? 'dark' : 'light',
      );
    }
  };

  componentDidMount = (): void => {
    // Getting dark mode value from localStorage!
    const storedValue = localStorage.getItem('dark');
    // Getting dark mode information from OS!
    const supportsDarkMode = () =>
      window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (storedValue && supportsDarkMode()) {
      this.setState({ dark: storedValue === 'true' });
    }
  };

  render(): JSX.Element {
    const { children } = this.props;
    const { dark } = this.state;

    return (
      <ThemeContext.Provider
        value={{
          dark,
          toggleDark: this.toggleDark,
        }}
      >
        {children}
      </ThemeContext.Provider>
    );
  }
}

export default ThemeContext;
export { ThemeProvider };
